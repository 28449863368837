<template>
    <v-card class="rounded-lg overflow-hidden" elevation="1">
        <v-card-text>
            <v-fade-transition mode="out-in" hide-on-leave>
                <div v-show="!loading">
                    <div class="d-flex justify-space-between align-center">
                        <div>
                            <div class="text-subtitle-2 text-medium-emphasis">Total Usuarios Activos</div>
                            <div class="text-h4 font-weight-bold">
                                {{ currentTotal }}
                            </div>
                        </div>
                        <v-icon :color="totalChange >= 0 ? 'success' : 'error'" size="48">
                            {{ totalChange >= 0 ? 'mdi-trending-up' : 'mdi-trending-down' }}
                        </v-icon>
                    </div>

                    <div class="mt-2">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <span :class="totalChange >= 0 ? 'success--text' : 'error--text'"
                                        class="font-weight-bold">
                                        {{ Math.abs(totalChange).toFixed(1) }}%
                                    </span>
                                    <span class="text-medium-emphasis"> vs mes anterior</span>
                                </span>
                            </template>
                            <span>Mes anterior: {{ previousTotal }} usuarios</span>
                        </v-tooltip>
                    </div>
                </div>
            </v-fade-transition>

            <v-fade-transition mode="out-in" hide-on-leave>
                <div v-show="loading" class="loader-container">
                    <div class="d-flex justify-space-between align-center">
                        <div>
                            <v-skeleton-loader type="text" width="110" class="mb-2" />
                            <v-skeleton-loader type="heading" width="160" />
                        </div>
                        <v-skeleton-loader type="avatar" width="48" height="48" />
                    </div>
                    <div class="mt-2">
                        <v-skeleton-loader type="text" width="140" />
                    </div>
                </div>
            </v-fade-transition>
        </v-card-text>
    </v-card>
</template>

<script>
import DashboardService from '@/services/DashboardService'

export default {
    name: 'TotalUsersCard',

    data() {
        return {
            loading: true,
            currentTotal: 0,
            previousTotal: 0,
            totalChange: 0
        }
    },

    mounted() {
        this.fetchData()
    },

    methods: {
        async fetchData() {
            this.loading = true
            try {
                const dashboardData = await DashboardService.getDashboardData()

                this.currentTotal = dashboardData.totalUsers.current
                this.previousTotal = dashboardData.totalUsers.previous

                // Calculate percentage change with protection against division by zero
                if (this.previousTotal === 0) {
                    this.totalChange = this.currentTotal > 0 ? 100 : 0
                } else {
                    this.totalChange = ((this.currentTotal - this.previousTotal) / Math.abs(this.previousTotal)) * 100
                }

            } catch (error) {
                console.error('Error fetching total users data:', error)
                // Set default values on error
                this.currentTotal = 0
                this.previousTotal = 0
                this.totalChange = 0
            } finally {
                this.loading = false
            }
        }
    }
}
</script>