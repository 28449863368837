import { collection, query, where, getDocs, getFirestore, getCountFromServer, getAggregateFromServer, sum, count } from 'firebase/firestore'
import moment from 'moment'

export default class DashboardService {
    static async getDashboardData() {
        const db = getFirestore()
        
        // Date ranges
        const currentStart = moment().startOf('month').toDate()
        const currentEnd = moment().endOf('month').toDate()
        const previousStart = moment().subtract(1, 'month').startOf('month').toDate()
        const previousEnd = moment().subtract(1, 'month').endOf('month').toDate()

        try {
            // Batch all queries together
            const [
                currentTotalUsers,
                previousTotalUsers,
                newUsersCurrentMonth,
                newUsersPreviousMonth,
                disabledCurrentMonth,
                disabledPreviousMonth,
                reEnabledCurrentMonth,
                reEnabledPreviousMonth,
                assistancesCurrentMonth,
                assistancesPreviousMonth,
                currentMonthMargins,
                previousMonthMargins,
                currentMonthSurveys,
                previousMonthSurveys,
                currentSurveyBasicStats,
                currentSurveySentimentStats,
                previousSurveyStats
            ] = await Promise.all([
                // Current total active users
                getCountFromServer(query(
                    collection(db, 'users'),
                    where('disabled', '==', false),
                    where('type', 'in', ['usuario', 'canje', 'representante', 'opengym', 'personalizado', 'gratis', 'paselibre', 'online'])
                )),

                // Previous month total - users who were active at the end of previous month
                getCountFromServer(query(
                    collection(db, 'users'),
                    where('type', 'in', ['usuario', 'canje', 'representante', 'opengym', 'personalizado', 'gratis', 'paselibre', 'online']),
                    where('createdAt', '<=', previousEnd),
                    where('disabled', '==', false)
                )),
                
                // New users current month
                getCountFromServer(query(
                    collection(db, 'users'),
                    where('createdAt', '>=', currentStart),
                    where('createdAt', '<=', currentEnd),
                    where('type', 'in', ['usuario', 'canje', 'representante', 'opengym', 'personalizado', 'gratis', 'paselibre', 'online'])
                )),

                // New users previous month
                getCountFromServer(query(
                    collection(db, 'users'),
                    where('createdAt', '>=', previousStart),
                    where('createdAt', '<=', previousEnd),
                    where('type', 'in', ['usuario', 'canje', 'representante', 'opengym', 'personalizado', 'gratis', 'paselibre', 'online'])
                )),

                // Disabled users current month
                getCountFromServer(query(
                    collection(db, 'disabledUsers'),
                    where('date', '>=', currentStart),
                    where('date', '<=', currentEnd)
                )),

                // Disabled users previous month
                getCountFromServer(query(
                    collection(db, 'disabledUsers'),
                    where('date', '>=', previousStart),
                    where('date', '<=', previousEnd)
                )),

                // Re-enabled users current month
                getCountFromServer(query(
                    collection(db, 'reEnabledUsers'),
                    where('date', '>=', currentStart),
                    where('date', '<=', currentEnd)
                )),

                // Re-enabled users previous month
                getCountFromServer(query(
                    collection(db, 'reEnabledUsers'),
                    where('date', '>=', previousStart),
                    where('date', '<=', previousEnd)
                )),

                // Assistances current month
                getCountFromServer(query(
                    collection(db, 'workouts'),
                    where('date', '>=', currentStart),
                    where('date', '<=', currentEnd)
                )),

                // Assistances previous month
                getCountFromServer(query(
                    collection(db, 'workouts'),
                    where('date', '>=', previousStart),
                    where('date', '<=', previousEnd)
                )),

                // Replace fetchMarginsData with direct aggregate queries
                this.fetchMonthMargins(db, currentStart, currentEnd),
                this.fetchMonthMargins(db, previousStart, previousEnd),

                // Satisfaction surveys current month
                getCountFromServer(query(
                    collection(db, 'surveys'),
                    where('date', '>=', currentStart),
                    where('date', '<=', currentEnd)
                )),

                // Satisfaction surveys previous month
                getCountFromServer(query(
                    collection(db, 'surveys'),
                    where('date', '>=', previousStart),
                    where('date', '<=', previousEnd)
                )),

                // Basic stats for current month
                getAggregateFromServer(query(
                    collection(db, 'surveys'),
                    where('date', '>=', currentStart),
                    where('date', '<=', currentEnd),
                    where('omitted', '==', false)
                ), {
                    totalRating: sum('averageRating'),
                    totalResponses: count(),
                    limpiezaRating: sum('questions.limpieza'),
                    atencionRating: sum('questions.atencion')
                }),

                // Additional stats for current month
                getAggregateFromServer(query(
                    collection(db, 'surveys'),
                    where('date', '>=', currentStart),
                    where('date', '<=', currentEnd),
                    where('omitted', '==', false)
                ), {
                    clasesRating: sum('questions.clases'),
                    instalacionesRating: sum('questions.instalaciones'),
                    positiveCount: count(where('sentiment', '==', 'Positivo', 'comment', '!=', null)),
                    neutralCount: count(where('sentiment', '==', 'Neutral', 'comment', '!=', null)),
                    negativeCount: count(where('sentiment', '==', 'Negativo', 'comment', '!=', null))
                }),

                // Previous month stats
                getAggregateFromServer(query(
                    collection(db, 'surveys'),
                    where('date', '>=', previousStart),
                    where('date', '<=', previousEnd),
                    where('omitted', '==', false)
                ), {
                    totalRating: sum('averageRating'),
                    totalResponses: count()
                })
            ])

            // Get the actual survey data in a single batch
            const [currentSurveys, previousSurveys] = await Promise.all([
                getDocs(query(
                    collection(db, 'surveys'),
                    where('date', '>=', currentStart),
                    where('date', '<=', currentEnd)
                )),
                getDocs(query(
                    collection(db, 'surveys'),
                    where('date', '>=', previousStart),
                    where('date', '<=', previousEnd)
                ))
            ])

            // Process survey data
            const currentData = this.processSurveyData(currentSurveys)
            const previousData = this.processSurveyData(previousSurveys)

            // Get comments for current month
            const commentsQuery = query(
                collection(db, 'surveys'),
                where('date', '>=', currentStart),
                where('date', '<=', currentEnd),
                where('omitted', '==', false),
                where('comment', '!=', null)
            )
            const commentsSnapshot = await getDocs(commentsQuery)

            // Count comments by sentiment
            const sentimentCounts = {
                Positivo: 0,
                Neutral: 0,
                Negativo: 0
            }

            const comments = commentsSnapshot.docs.map(doc => {
                const data = doc.data()
                if (data.sentiment) {
                    sentimentCounts[data.sentiment]++
                }
                return {
                    text: data.comment,
                    user: data.userName,
                    date: data.date,
                    sentiment: data.sentiment
                }
            })

            // Combine stats from both queries
            const basicStats = currentSurveyBasicStats.data()
            const sentimentStats = currentSurveySentimentStats.data()
            const totalResponses = basicStats.totalResponses || 1

            // Calculate question ratings
            const questions = [
                { 
                    text: 'Limpieza y orden', 
                    rating: basicStats.limpiezaRating / totalResponses 
                },
                { 
                    text: 'Atención del personal', 
                    rating: basicStats.atencionRating / totalResponses 
                },
                { 
                    text: 'Calidad de las clases', 
                    rating: sentimentStats.clasesRating / totalResponses 
                },
                { 
                    text: 'Instalaciones', 
                    rating: sentimentStats.instalacionesRating / totalResponses 
                }
            ].sort((a, b) => b.rating - a.rating)

            return {
                totalUsers: {
                    current: currentTotalUsers.data().count,
                    previous: previousTotalUsers.data().count + 
                             disabledPreviousMonth.data().count - 
                             reEnabledPreviousMonth.data().count
                },
                newUsers: {
                    current: newUsersCurrentMonth.data().count,
                    previous: newUsersPreviousMonth.data().count
                },
                disabledUsers: {
                    current: disabledCurrentMonth.data().count,
                    previous: disabledPreviousMonth.data().count
                },
                reEnabledUsers: {
                    current: reEnabledCurrentMonth.data().count,
                    previous: reEnabledPreviousMonth.data().count
                },
                assistances: {
                    current: assistancesCurrentMonth.data().count,
                    previous: assistancesPreviousMonth.data().count
                },
                margins: {
                    current: currentMonthMargins,
                    previous: previousMonthMargins
                },
                satisfaction: {
                    current: {
                        average: basicStats.totalRating / totalResponses,
                        questionSatisfaction: questions,
                        sentiments: {
                            Positivo: sentimentCounts.Positivo,
                            Neutral: sentimentCounts.Neutral,
                            Negativo: sentimentCounts.Negativo
                        },
                        comments
                    },
                    previous: {
                        average: previousSurveyStats.data().totalRating / 
                                (previousSurveyStats.data().totalResponses || 1)
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching dashboard data:', error)
            throw error
        }
    }

    static async fetchMonthMargins(db, startDate, endDate) {
        // Get payments sum (ingress)
        const paymentsQuery = query(
            collection(db, 'payments'),
            where('createdAt', '>=', startDate),
            where('createdAt', '<=', endDate)
        )
        
        // Get expenses sum (egress)
        const expensesQuery = query(
            collection(db, 'ingress-egress'),
            where('date', '>=', startDate),
            where('date', '<=', endDate),
            where('type', '==', 'egress')
        )

        // Get other income sum (ingress)
        const ingressQuery = query(
            collection(db, 'ingress-egress'),
            where('date', '>=', startDate),
            where('date', '<=', endDate),
            where('type', '==', 'ingress')
        )

        const [paymentsSum, expensesSum, ingressSum] = await Promise.all([
            getAggregateFromServer(paymentsQuery, {
                totalAmount: sum('total')
            }),
            getAggregateFromServer(expensesQuery, {
                totalAmount: sum('payAmount')
            }),
            getAggregateFromServer(ingressQuery, {
                totalAmount: sum('payAmount')
            })
        ])

        const totalIngress = (paymentsSum.data().totalAmount || 0) + (ingressSum.data().totalAmount || 0)
        const totalEgress = expensesSum.data().totalAmount || 0

        return totalIngress - totalEgress
    }

    static processSurveyData(snapshot) {
        let total = 0
        let count = 0
        const sentiments = { Positivo: 0, Neutral: 0, Negativo: 0 }
        const questionTotals = new Map()
        const questionCounts = new Map()
        const comments = []

        snapshot.forEach(doc => {
            const data = doc.data()
            if (data.data) {
                data.data.forEach(q => {
                    if (q.rating) {
                        total += q.rating
                        count++

                        if (!questionTotals.has(q.question)) {
                            questionTotals.set(q.question, 0)
                            questionCounts.set(q.question, 0)
                        }
                        questionTotals.set(q.question, questionTotals.get(q.question) + q.rating)
                        questionCounts.set(q.question, questionCounts.get(q.question) + 1)
                    }
                    // Collect comments
                    if (q.comment) {
                        comments.push({
                            text: q.comment,
                            user: data.user,
                            date: data.date,
                            sentiment: data.sentiment
                        })
                    }
                })
                if (data.sentiment) {
                    sentiments[data.sentiment] = (sentiments[data.sentiment] || 0) + 1
                }
            }
        })

        const questionSatisfaction = Array.from(questionTotals.entries())
            .map(([question, total]) => ({
                text: question.replace(/^\d+\)\s*/, ''),
                rating: questionCounts.get(question) > 0 ? total / questionCounts.get(question) : 0
            }))
            .filter(q => !q.text.toLowerCase().includes('comentarios'))
            .sort((a, b) => b.rating - a.rating)

        return {
            average: count > 0 ? total / count : 0,
            questionSatisfaction,
            sentiments,
            comments
        }
    }
} 